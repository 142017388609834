import MDBox from "../../../../../components/MDBox";
import { useEffect, useState } from "react";
import PropTypes from "prop-types";
import {
  serverCommunicationUtil,
  sessionChecker,
} from "../../../../../common/util/serverCommunicationUtil";
import { getSessionStorage } from "../../../../../common/common";
import { useNavigate } from "react-router-dom";
import DrivenTable from "../../../../../components/DrivenTable";
import MDTypography from "../../../../../components/MDTypography";

function CompanyPerformance(props) {
  const { setAlertColor, setAlertText, setUseAlert } = props;
  const [showPage, setShowPage] = useState(false);

  const [data, setData] = useState();
  const [qualityRows, setQualityRows] = useState([]);
  const [qualityColumns, setQualityColumns] = useState([]);
  const [statusRows, setStatusRows] = useState([]);
  const [statusColumns, setStatusColumns] = useState([]);

  const navigate = useNavigate();

  const getData = () => {
    serverCommunicationUtil("main", "axioGet", "/performance/crm/company/init", {
      companyId: getSessionStorage("companyId"),
    })
      .then((result) => {
        let tempQualityRows = [];
        let tempQualityColumns = [
          {
            name: "담당자",
            width: "90px",
            type: "text",
          },
        ];
        let tempStatusRows = [];
        let tempStatusColumns = [
          {
            name: "담당자",
            width: "90px",
            type: "text",
          },
        ];

        result.forEach((data) => {
          const qualityKeys = Object.keys(data.quality);

          qualityKeys.forEach((key) => {
            if (
              !tempQualityColumns.map((qualityColumnName) => qualityColumnName.name).includes(key)
            ) {
              tempQualityColumns.push({
                name: key,
                width: "113px",
                type: "text",
              });
            }
          });

          data.quality.담당자 = data.name;
          tempQualityRows.push(data.quality);
        });

        result.forEach((data) => {
          const statusKeys = Object.keys(data.status);

          statusKeys.forEach((key) => {
            if (!tempStatusColumns.map((statusColumnName) => statusColumnName.name).includes(key)) {
              tempStatusColumns.push({
                name: key,
                width: "113px",
                type: "text",
              });
            }
          });

          data.status.담당자 = data.name;
          tempStatusRows.push(data.status);
        });

        setData(result);
        setQualityRows(tempQualityRows);
        setQualityColumns(tempQualityColumns);
        setStatusRows(tempStatusRows);
        setStatusColumns(tempStatusColumns);
        console.log(result);
      })
      .catch((error) => {
        setAlertColor("error");
        setAlertText("데이터가 없습니다.");
        setUseAlert(true);
        setTimeout(() => {
          setUseAlert(false);
        }, 1500);
      });
  };

  useEffect(() => {
    sessionChecker()
      .then((checkerResult) => {
        if (checkerResult === "success") {
          setShowPage(true);
          getData();
        } else {
          navigate("/" + getSessionStorage("companyId") + "/login");
        }
      })
      .catch((error) => navigate("/" + getSessionStorage("companyId") + "/login"));
  }, []);

  if (!showPage) {
    return null; // 혹은 로딩 스피너 등을 반환.
  }

  return (
    <MDBox sx={{ padding: "16px", overflowX: "auto", width: "100%" }}>
      {data ? (
        <MDBox>
          <MDBox>
            <MDTypography variant="h3" fontWeight="bold">
              금월 실적
            </MDTypography>
            <MDBox display={"flex"} justifyContent={"space-around"}>
              <MDBox>
                <MDTypography variant="body1" fontWeight="bold">
                  품질별 실적
                </MDTypography>
                <MDTypography variant="body2" fontWeight="medium">
                  전체 총 {data.length}명 데이터
                </MDTypography>
                <DrivenTable
                  rows={qualityRows}
                  columns={qualityColumns}
                  useDel={false}
                  useModify={false}
                  useSearch={false}
                  useSort={true}
                  usePaging={false}
                  useCustomCell={false}
                  entries={["100"]}
                />
              </MDBox>
              <MDBox>
                <MDTypography variant="body1" fontWeight="bold">
                  상태별 실적
                </MDTypography>
                <MDTypography variant="body2" fontWeight="medium">
                  전체 총 {data.length}명 데이터
                </MDTypography>
                <DrivenTable
                  rows={statusRows}
                  columns={statusColumns}
                  useDel={false}
                  useModify={false}
                  useSearch={false}
                  useSort={true}
                  usePaging={false}
                  useCustomCell={false}
                  entries={["100"]}
                />
              </MDBox>
            </MDBox>
          </MDBox>
          <MDBox>
            <MDTypography variant="h3" fontWeight="bold">
              월별 실적
            </MDTypography>
          </MDBox>
        </MDBox>
      ) : (
        <MDTypography variant="h3" fontWeight="bold">
          데이터를 불러오는 중입니다.
        </MDTypography>
      )}
    </MDBox>
  );
}

export default CompanyPerformance;

CompanyPerformance.propTypes = {
  setAlertColor: PropTypes.func.isRequired,
  setAlertText: PropTypes.func.isRequired,
  setUseAlert: PropTypes.func.isRequired,
};
