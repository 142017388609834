import PropTypes from "prop-types";
import CompanyPerformance from "../company";
import { useEffect, useState } from "react";
import {
  serverCommunicationUtil,
  sessionChecker,
} from "../../../../../common/util/serverCommunicationUtil";
import { getSessionStorage } from "../../../../../common/common";
import MDBox from "../../../../../components/MDBox";
import { useNavigate } from "react-router-dom";

function MemberPerformance(props) {
  const { setAlertColor, setAlertText, setUseAlert } = props;
  const [showPage, setShowPage] = useState(false);

  const navigate = useNavigate();

  const getData = () => {
    serverCommunicationUtil("main", "axioGet", "/performance/ad/init", {})
      .then((result) => {
        // setDataList(result);
        console.log(result);
      })
      .catch((error) => {
        setAlertColor("error");
        setAlertText("데이터가 없습니다.");
        setUseAlert(true);
        setTimeout(() => {
          setUseAlert(false);
        }, 1500);
      });
  };

  useEffect(() => {
    sessionChecker()
      .then((checkerResult) => {
        if (checkerResult === "success") {
          setShowPage(true);
          // getData();
        } else {
          navigate("/" + getSessionStorage("companyId") + "/login");
        }
      })
      .catch((error) => navigate("/" + getSessionStorage("companyId") + "/login"));
  }, []);

  if (!showPage) {
    return null; // 혹은 로딩 스피너 등을 반환.
  }

  return (
    <MDBox sx={{ padding: "16px", overflowX: "auto", width: "100%" }}>
      <MDBox></MDBox>
    </MDBox>
  );
}

export default MemberPerformance;

MemberPerformance.propTypes = {
  setAlertColor: PropTypes.func.isRequired,
  setAlertText: PropTypes.func.isRequired,
  setUseAlert: PropTypes.func.isRequired,
};
