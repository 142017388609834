import DrivenAlert from "../../../components/DrivenAlert";
import DashboardNavbar from "../../common/Navbars/DashboardNavbar";
import React, { useEffect, useState } from "react";
import DashboardLayout from "../../common/LayoutContainers/DashboardLayout";
import MDBox from "../../../components/MDBox";
import MDTypography from "../../../components/MDTypography";
import { sessionChecker } from "../../../common/util/serverCommunicationUtil";
import { getConst, getSessionStorage } from "../../../common/common";
import { useNavigate } from "react-router-dom";
import Card from "@mui/material/Card";
import CompanyPerformance from "./components/company";
import MemberPerformance from "./components/member";

function PerformanceByAd() {
  const [alertColor, setAlertColor] = useState("info");
  const [alertText, setAlertText] = useState("");
  const [useAlert, setUseAlert] = useState(false);

  return (
    <DashboardLayout>
      {useAlert && <DrivenAlert alertColor={alertColor} alertText={alertText} />}
      <DashboardNavbar />
      <MDBox my={3}>
        <MDBox my={3}>
          <MDBox display="flex" justifyContent="space-between">
            <MDBox height="100%" mt={0.5} lineHeight={1} p={2}>
              <MDTypography variant="h4" fontWeight="medium">
                수집 실적
              </MDTypography>
              <MDTypography variant="body2" color="text" fontWeight="regular">
                고객 DB 수집 실적
              </MDTypography>
            </MDBox>
            <MDBox display="block" style={{ textAlign: "center" }} p={2}></MDBox>
          </MDBox>
        </MDBox>
      </MDBox>
      <Card>
        {getConst("ADAC") === getSessionStorage("userType") && (
          <CompanyPerformance
            setAlertColor={setAlertColor}
            setAlertText={setAlertText}
            setUseAlert={setUseAlert}
          />
        )}
        {getConst("AAME") === getSessionStorage("userType") && (
          <MemberPerformance
            setAlertColor={setAlertColor}
            setAlertText={setAlertText}
            setUseAlert={setUseAlert}
          />
        )}
        {getConst("DEVL") === getSessionStorage("userType") && (
          <>
            <CompanyPerformance
              setAlertColor={setAlertColor}
              setAlertText={setAlertText}
              setUseAlert={setUseAlert}
            />
            <MemberPerformance
              setAlertColor={setAlertColor}
              setAlertText={setAlertText}
              setUseAlert={setUseAlert}
            />
          </>
        )}
      </Card>
    </DashboardLayout>
  );
}

export default PerformanceByAd;
