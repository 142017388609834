import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { sessionChecker } from "../../../common/util/serverCommunicationUtil";
import { getSessionStorage } from "../../../common/common";
import DashboardLayout from "../../common/LayoutContainers/DashboardLayout";
import DrivenAlert from "../../../components/DrivenAlert";
import DashboardNavbar from "../../common/Navbars/DashboardNavbar";
import MDBox from "../../../components/MDBox";
import MDTypography from "../../../components/MDTypography";
import Card from "@mui/material/Card";

function PerformanceByClient() {
  const [showPage, setShowPage] = useState(false);
  const [alertColor, setAlertColor] = useState("info");
  const [alertText, setAlertText] = useState("");
  const [useAlert, setUseAlert] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    sessionChecker()
      .then((checkerResult) => {
        if (checkerResult === "success") {
          setShowPage(true);
        } else {
          navigate("/" + getSessionStorage("companyId") + "/login");
        }
      })
      .catch((error) => navigate("/" + getSessionStorage("companyId") + "/login"));
  }, []);

  if (!showPage) {
    return null; // 혹은 로딩 스피너 등을 반환.
  }

  return (
    <DashboardLayout>
      {useAlert && <DrivenAlert alertColor={alertColor} alertText={alertText} />}
      <DashboardNavbar />
      <MDBox my={3}>
        <MDBox my={3}>
          <MDBox display="flex" justifyContent="space-between">
            <MDBox height="100%" mt={0.5} lineHeight={1} p={2}>
              <MDTypography variant="h4" fontWeight="medium">
                Outbound 전체 목록
              </MDTypography>
              <MDTypography variant="body2" color="text" fontWeight="regular">
                Outbound 목록
              </MDTypography>
            </MDBox>
            <MDBox display="block" style={{ textAlign: "center" }} p={2}></MDBox>
          </MDBox>
        </MDBox>
      </MDBox>
      <Card>테스트</Card>
    </DashboardLayout>
  );
}

export default PerformanceByClient;
