import MDBox from "../../../../../components/MDBox";
import { useEffect, useState } from "react";
import {
  serverCommunicationUtil,
  sessionChecker,
} from "../../../../../common/util/serverCommunicationUtil";
import { getSessionStorage } from "../../../../../common/common";
import { useNavigate } from "react-router-dom";
import PropTypes from "prop-types";
import MDTypography from "../../../../../components/MDTypography";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import { PieChart } from "@mui/x-charts/PieChart";
import DefaultLineChart from "../../../../common/Charts/LineCharts/DefaultLineChart";

function CompanyPerformance(props) {
  const { setAlertColor, setAlertText, setUseAlert } = props;
  const [showPage, setShowPage] = useState(false);

  const [dataList, setDataList] = useState([]);
  const [selectCdbtCode, setSelectCdbtCode] = useState("");
  const [initPerformance, setInitPerformance] = useState({});
  const [totalChartData, setTotalChartData] = useState([]);
  const [weekChartData, setWeekChartData] = useState([]);
  const [monthChartData, setMonthChartData] = useState([]);
  const [yearChartData, setYearChartData] = useState([]);
  const [lineChartLabels, setLineChartLabels] = useState([]);
  const [lineChartData, setLineChartData] = useState([]);

  const navigate = useNavigate();

  const getData = () => {
    serverCommunicationUtil("main", "axioGet", "/performance/ad/init", {})
      .then((result) => {
        setDataList(result);
      })
      .catch((error) => {
        setAlertColor("error");
        setAlertText("데이터가 없습니다.");
        setUseAlert(true);
        setTimeout(() => {
          setUseAlert(false);
        }, 1500);
      });
  };

  function getRandomColor() {
    const colors = ["primary", "secondary", "info", "success", "warning", "error", "light", "dark"];

    const randomIndex = Math.floor(Math.random() * colors.length);
    return colors[randomIndex];
  }

  useEffect(() => {
    if (dataList.length > 0 && selectCdbtCode !== "") {
      const selectedData = dataList.filter((data) => data.cdbtCode === selectCdbtCode)[0];
      console.log(selectedData);

      let tempTotalChartData = [];
      if (selectedData !== undefined) {
        Object.keys(selectedData.total).forEach((key, keyIndex) => {
          if (key !== "totalData") {
            if (selectedData.total[key] > 0) {
              tempTotalChartData.push({ id: keyIndex, value: selectedData.total[key], label: key });
            }
          }
        });
        setTotalChartData(tempTotalChartData);

        let tempWeekChartData = [];
        Object.keys(selectedData.minusOneWeek).forEach((key, keyIndex) => {
          if (key !== "totalData") {
            if (selectedData.minusOneWeek[key] > 0) {
              tempWeekChartData.push({
                id: keyIndex,
                value: selectedData.minusOneWeek[key],
                label: key,
              });
            }
          }
        });
        setWeekChartData(tempWeekChartData);

        let tempMonthChartData = [];
        Object.keys(selectedData.minusOneMonth).forEach((key, keyIndex) => {
          if (key !== "totalData") {
            if (selectedData.minusOneMonth[key] > 0) {
              tempMonthChartData.push({
                id: keyIndex,
                value: selectedData.minusOneMonth[key],
                label: key,
              });
            }
          }
        });
        setMonthChartData(tempMonthChartData);

        let tempYearChartData = [];
        Object.keys(selectedData.minusOneYear).forEach((key, keyIndex) => {
          if (key !== "totalData") {
            if (selectedData.minusOneYear[key] > 0) {
              tempYearChartData.push({
                id: keyIndex,
                value: selectedData.minusOneYear[key],
                label: key,
              });
            }
          }
        });
        setYearChartData(tempYearChartData);
      }

      if (selectedData.list.length > 0) {
        let tempLineChartLabels = [
          ...new Set(
            selectedData.list.map((listData) => {
              return listData.createDate.toString().substring(0, 10);
            })
          ),
        ];

        selectedData.list.sort((a, b) => new Date(a.createDate) - new Date(b.createDate));

        let tempLineChartData = [
          {
            label: "총 데이터",
            color: getRandomColor(),
            data: Array(tempLineChartLabels.length).fill(0),
          },
        ];

        tempTotalChartData.forEach((totalChartData) => {
          tempLineChartData.push({
            label: totalChartData.label,
            color: getRandomColor(),
            data: Array(tempLineChartLabels.length).fill(0),
          });
        });

        selectedData.list.forEach((listData) => {
          let index = tempLineChartLabels.indexOf(listData.createDate.toString().substring(0, 10));
          tempLineChartData.forEach((chartData, i) => {
            if (listData.cdbqCode === chartData.label || chartData.label === "총 데이터") {
              chartData.data[index]++;
            }
          });
        });
        setLineChartLabels(tempLineChartLabels);
        setLineChartData(tempLineChartData);
        console.log(tempLineChartLabels);
        console.log(tempLineChartData);
      }

      setInitPerformance(selectedData);
    }
  }, [selectCdbtCode]);

  useEffect(() => {
    sessionChecker()
      .then((checkerResult) => {
        if (checkerResult === "success") {
          setShowPage(true);
          getData();
        } else {
          navigate("/" + getSessionStorage("companyId") + "/login");
        }
      })
      .catch((error) => navigate("/" + getSessionStorage("companyId") + "/login"));
  }, []);

  if (!showPage) {
    return null; // 혹은 로딩 스피너 등을 반환.
  }
  return (
    <MDBox sx={{ padding: "16px", overflowX: "auto", width: "100%" }}>
      <MDBox>
        <Autocomplete
          size="small"
          options={dataList.map((item) => item.url)}
          renderInput={(params) => <TextField {...params} label="고객 DB 선택" />}
          onChange={(event, newValue) => {
            dataList.forEach((data) => {
              if (data.url === newValue) {
                setSelectCdbtCode(data.cdbtCode);
                return true;
              }
            });
          }}
        />
        <MDBox>
          {selectCdbtCode !== "" ? (
            initPerformance !== {} ? (
              <MDBox>
                <MDBox display="flex" justifyContent={"space-around"} sx={{ padding: "10px" }}>
                  <MDBox
                    sx={{
                      padding: "10px",
                      border: "2px solid black",
                      borderRadius: "10px",
                    }}
                  >
                    <MDTypography variant="body1" fontWeight="bold">
                      전체 데이터
                    </MDTypography>
                    <MDTypography variant="body2" fontWeight="medium">
                      전체 총 {initPerformance.total?.totalData}개 데이터
                    </MDTypography>

                    {initPerformance.total?.totalData > 0 ? (
                      <PieChart
                        series={[
                          {
                            data: totalChartData,
                          },
                        ]}
                        width={400}
                        height={200}
                      />
                    ) : (
                      <MDTypography sx={{ marginTop: "90px" }} variant="body2" fontWeight="regular">
                        출력가능한 데이터가 없습니다
                      </MDTypography>
                    )}
                    <MDBox>
                      {totalChartData.map((data, dataIndex) => (
                        <MDTypography variant="body2" fontWeight="regular" key={dataIndex}>
                          {data.label} : {data.value}
                        </MDTypography>
                      ))}
                    </MDBox>
                  </MDBox>
                  <MDBox
                    sx={{
                      padding: "10px",
                      border: "2px solid black",
                      borderRadius: "10px",
                    }}
                  >
                    <MDTypography variant="body1" fontWeight="bold">
                      최근 1년 데이터
                    </MDTypography>
                    <MDTypography variant="body2" fontWeight="medium">
                      최근 1년 총 {initPerformance.minusOneYear?.totalData}개 데이터
                    </MDTypography>
                    {initPerformance.minusOneYear?.totalData > 0 ? (
                      <PieChart
                        series={[
                          {
                            data: yearChartData,
                          },
                        ]}
                        width={400}
                        height={200}
                      />
                    ) : (
                      <MDTypography sx={{ marginTop: "90px" }} variant="body2" fontWeight="regular">
                        출력가능한 데이터가 없습니다
                      </MDTypography>
                    )}
                    <MDBox>
                      {yearChartData.map((data, dataIndex) => (
                        <MDTypography variant="body2" fontWeight="regular" key={dataIndex}>
                          {data.label} : {data.value}
                        </MDTypography>
                      ))}
                    </MDBox>
                  </MDBox>
                  <MDBox
                    sx={{
                      padding: "10px",
                      border: "2px solid black",
                      borderRadius: "10px",
                    }}
                  >
                    <MDTypography variant="body1" fontWeight="bold">
                      최근 한달 데이터
                    </MDTypography>
                    <MDTypography variant="body2" fontWeight="medium">
                      최근 한달 총 {initPerformance.minusOneMonth?.totalData}개 데이터
                    </MDTypography>
                    {initPerformance.minusOneMonth?.totalData > 0 ? (
                      <PieChart
                        series={[
                          {
                            data: monthChartData,
                          },
                        ]}
                        width={400}
                        height={200}
                      />
                    ) : (
                      <MDTypography sx={{ marginTop: "90px" }} variant="body2" fontWeight="regular">
                        출력가능한 데이터가 없습니다
                      </MDTypography>
                    )}
                    <MDBox>
                      {monthChartData.map((data, dataIndex) => (
                        <MDTypography variant="body2" fontWeight="regular" key={dataIndex}>
                          {data.label} : {data.value}
                        </MDTypography>
                      ))}
                    </MDBox>
                  </MDBox>
                  <MDBox
                    sx={{
                      padding: "10px",
                      border: "2px solid black",
                      borderRadius: "10px",
                    }}
                  >
                    <MDTypography variant="body1" fontWeight="bold">
                      최근 일주일 데이터
                    </MDTypography>
                    <MDTypography variant="body2" fontWeight="medium">
                      최근 일주일 총 {initPerformance.minusOneWeek?.totalData}개 데이터
                    </MDTypography>
                    {initPerformance.minusOneWeek?.totalData > 0 ? (
                      <PieChart
                        series={[
                          {
                            data: weekChartData,
                          },
                        ]}
                        width={400}
                        height={200}
                      />
                    ) : (
                      <MDTypography sx={{ marginTop: "90px" }} variant="body2" fontWeight="regular">
                        출력가능한 데이터가 없습니다
                      </MDTypography>
                    )}
                    <MDBox>
                      {weekChartData.map((data, dataIndex) => (
                        <MDTypography variant="body2" fontWeight="regular" key={dataIndex}>
                          {data.label} : {data.value}
                        </MDTypography>
                      ))}
                    </MDBox>
                  </MDBox>
                </MDBox>
                <MDBox
                  sx={{
                    padding: "10px",
                  }}
                >
                  <DefaultLineChart
                    title="데이터 수집 추이량"
                    chart={{
                      labels: lineChartLabels,
                      datasets: lineChartData,
                    }}
                  />
                </MDBox>
              </MDBox>
            ) : null
          ) : (
            <MDTypography variant="body2" color="text" fontWeight="regular">
              고객 DB를 선택해 주세요
            </MDTypography>
          )}
        </MDBox>
      </MDBox>
    </MDBox>
  );
}

export default CompanyPerformance;

CompanyPerformance.propTypes = {
  setAlertColor: PropTypes.func.isRequired,
  setAlertText: PropTypes.func.isRequired,
  setUseAlert: PropTypes.func.isRequired,
};
